import React, { useEffect } from 'react';
import styled from 'styled-components';

import { mediaQuery } from 'src/theme/breakpoints';

const Container = styled.div`
  height: 1000px;
  margin-top: 62px;
  overflow-y: scroll;
  position: relative;
  width: 100%;

  ${mediaQuery('lg')} {
    margin-top: 92px;
  }
`;

const Iframe: React.FC = () => {
  useEffect(() => {
    const nova_iframe_options = {
      baseUrl: 'https://piligrimas.lt',
      lang: 'lt',
      containerId: 'iframe',
      target: 'search',
      domain: 'https://www.novaturas.lt',
      wid: 'piligrimas2023',
      agentName: 'Aušra Pėkienė',
      agentPhone: '+37061496964',
    };

    const script = document.createElement('script');
    script.text = `
      var nova_iframe_options = ${JSON.stringify(nova_iframe_options)};
      (function(d, t, o, u){
        var e = d.createElement(t),
        s = d.getElementsByTagName(t)[0];
        e.src = u;
        s.parentNode.insertBefore(e, s);
      })(document, 'script', nova_iframe_options, 'https://www.novaturas.lt/static/js/iframe.js');
    `;

    document.head.appendChild(script);

    return () => {
      document.head.removeChild(script);
    };
  }, []);

  return (
    <Container>
      <div id="iframe"></div>
    </Container>
  );
};

export default Iframe;
