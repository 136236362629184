import React, { FC } from 'react';

import SEO from 'src/components/Seo/Seo';
import Iframe from 'src/components/Iframe';
import Header from 'src/components/Header';

const NovaturasPage: FC = () => {
  return (
    <>
      <SEO
        title="Novaturo kelionės | Piligrimas"
        description="Rinkitės mūsų kelionių partnerio Novaturas poilsines keliones."
      />
      <Header isSurvey />
      <Iframe />
    </>
  );
};

export default NovaturasPage;
